import { ISimpleArray } from 'app/types/interface'

export function copy(value: string): void {
  const input = document.createElement('input') as HTMLInputElement
  input.value = value
  input.style.visibility = 'none'
  input.style.position = 'fixed'
  document.body.appendChild(input)
  input.focus()
  input.select()
  document.execCommand('copy')
  document.body.removeChild(input)
}

export function arrayNumbers(max: number): number[] {
  return Array.from({ length: max }, (v, k) => k + 1)
}

export function lazyUpdate(refs: any[], updateData: any[], ignoreAttrs: string[]): void {
  const attr = ignoreAttrs[0]
  refs.forEach(t => {
    const data = updateData.find(d => d[attr] === t[attr])
    if (!data) {
      updateData.push(t)
      return
    }
    for (const [key, value] of Object.entries(t)) {
      if (!ignoreAttrs.includes(key)) {
        data[key] = value
      }
    }
  })
}

export function enumToArray(input: object, ignoreAttrs: number[] = []): {
  id: number;
  value: string;
}[] | undefined {
  if (typeof input !== 'object') {
    return undefined
  }
  const result = []
  for (const [key, value] of Object.entries(input)) {
    if (Number.isNaN(+key)) {
      continue
    }
    if (!ignoreAttrs.includes(+key)) {
      result.push({ id: +key, value })
    }
  }
  return result
}

export function sort(array: any[] = [], field: string): any[] {
  return array.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0))
}

export function toFixed(item: number, digit = 2): number {
  if (!digit) {
    return Math.trunc(item)
  }
  const result = `${item}`.match(new RegExp(`^-?\\d+(?:\\.\\d{0,${digit}})?`))
  return +result[0]
}

export function getStorageItem(name: string, init = {}): any {
  return JSON.parse(localStorage.getItem(name)) || init
}

export function setStorageItem(name: string, data: any): any {
  return localStorage.setItem(name, JSON.stringify(data))
}

export function deleteEmptyParams(params: { [param: string]: number | string | string[] | boolean }, ignoreAttrs: string[] = []): {
  [param: string]: string | string[]
} {
  const result: {
    [param: string]: string | string[]
  } = {}
  for (const [key, value] of Object.entries(params)) {
    if (!value) {
      delete params[key]
    } else {
      if (!ignoreAttrs.includes(key)) {
        result[key] = value.toString()
      }
    }
  }
  return result
}

export function objectToArray<T = string>(obj: { [key: string]: any }, allowAttrs: string[] = []): ISimpleArray<T>[] {
  const result: { [key: string]: string | number }[] = []
  for (const [key, value] of Object.entries(obj)) {
    if (!allowAttrs.length || allowAttrs.includes(key)) {
      result.push({ key, value, index: allowAttrs.indexOf(key) })
    }
  }
  return sort(result, 'index').map(t => ({
    key: t.key,
    value: t.value
  }))
}

export function splitInfoArrays<T>(array: T[], noOfItems: number): T[][] {
  const result: T[][] = []
  let index = 0
  array.forEach(item => {
    if (!result[index]) {
      result[index] = []
    }
    result[index].push(item)
    if (result[index].length === noOfItems) {
      index = ++index
    }
  })
  return result
}
