import { trigger, transition, animate, style, state } from '@angular/animations'

export const slideInOut = trigger('slideInOut', [
  state('in', style({ transform: 'translate3d(0 ,0 ,0)' })),
  state('out', style({ transform: 'translate3d(100%, 0, 0)' })),
  transition('in <=> out', animate('.3s ease-in-out')),
])

export const slideUpDown = trigger('slideUpDown', [
  state('up', style({ transform: 'translateY(-100%)' })),
  state('down', style({ transform: 'translateY(0)' })),
  transition('up <=> down', animate('.3s ease-in-out')),
])

export const fadeInOut = trigger('fadeInOut', [
  state('in', style({ opacity: 1 })),
  state('out', style({ opacity: 0 })),
  transition('in <=> out', animate('.3s ease-in-out'))
])

export const slideFromBottom = trigger('slideFromBottom', [
  state('up', style({ bottom: 0 })),
  state('down', style({ bottom: '-100%' })),
  transition('up <=> down', animate('.3s ease-in-out')),
])
