import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

const routes: Routes = [{
  path: '',
  loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
}, {
  path: '',
  loadChildren: () => import('./main/main.module').then(m => m.MainModule),
}, {
  path: 'auth',
  loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
}, {
  path: '**',
  redirectTo: ''
}]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
