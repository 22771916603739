import { filter, map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Subject, Observable } from 'rxjs'

interface IEventBus {
  key: string
  data?: any
}

@Injectable({ providedIn: 'root' })
export class EventBusService {

  private eventBus: Subject<IEventBus>
  private separator = ':'

  constructor() {
    this.eventBus = new Subject<IEventBus>()
  }

  emit(key: string, data?: any): void {
    if (typeof key !== 'string' || !key.length) {
      throw new Error('key must be a string and mustn\'t be empty.')
    }
    this.eventBus.next({ key, data })
  }

  on<T>(key: string): Observable<T> {
    return this.eventBus.asObservable().pipe(
      filter(event => {
        if (!event) {
          return false
        }
        return this.keyMatch(event.key, key)
      }),
      map(event => event.data as T)
    )
  }

  private keyMatch(key: string, wildcard: string): boolean {
    const w = '*'
    const ww = '**'
    const partMatch = (wl: string, k: string) => {
      return (wl === w) || (wl === k)
    }
    const sep = this.separator
    const kArr = key.split(sep)
    const wArr = wildcard.split(sep)
    const kLen = kArr.length
    const wLen = wArr.length
    const max = Math.max(kLen, wLen)

    for (let i = 0; i < max; i++) {
      const cK = kArr[i]
      const cW = wArr[i]
      if (cW === ww && (typeof cK !== 'undefined')) {
        return true
      }
      if (!partMatch(cW, cK)) {
        return false
      }
    }
    return true
  }

}
