<nav class="navbar navbar-expand-xl bg-white px-20 py-0">
  <div class="d-flex w-100">
    <a routerLink="" class="me-auto me-xl-0">
      <img src="assets/imgs/brand.svg" alt="" class="my-12" [style.height]="'1.5rem'">
    </a>
    <a *ngIf="!isAuth" class="btn btn-dark align-self-center d-xl-none me-16 cursor_pointer" translate="Login"
      (click)="login()"></a>
    <i class="far fa-bars fa-2x align-self-center d-xl-none text-secondary cursor_pointer" (click)="state = 'in'"></i>
    <div class="d-none d-xl-inline mx-auto">
      <ng-template [ngTemplateOutlet]="menuTemp"></ng-template>
    </div>
    <div class="d-none d-xl-flex align-items-center ms-auto">
      <ng-container *ngIf="!isAuth">
        <a class="text-dark me-30" translate="Register" routerLink="/auth/register"></a>
        <a class="text-danger cursor_pointer" translate="Login" (click)="login()"></a>
      </ng-container>
      <ng-container *ngIf="isAuth">
        <ng-template [ngTemplateOutlet]="accountTemp"></ng-template>
      </ng-container>
      <div class="separator mx-30"></div>
    </div>
    <div class="d-none d-xl-block align-self-center">
      <ng-template [ngTemplateOutlet]="langTemp"></ng-template>
    </div>
  </div>
</nav>
<div class="menu bg-white d-xl-none overflow-auto pt-16" [@slideInOut]="state">
  <div class="px-20 d-flex align-items-center justify-content-between">
    <div *ngIf="isAuth">
      <ng-template [ngTemplateOutlet]="userTemp"></ng-template>
    </div>
    <img *ngIf="!isAuth" src="assets/imgs/brand.svg" alt="" [style.height]="'1.25rem'">
    <i class="far fa-times fa-2x text-secondary cursor_pointer" (click)="close()"></i>
  </div>
  <hr class="mb-0">
  <div class="px-20">
    <ng-template *ngTemplateOutlet="menuTemp"></ng-template>
  </div>
  <hr class="m-0">
  <ng-container *ngIf="isAuth">
    <div class="px-20">
      <ng-template *ngTemplateOutlet="profileTemp"></ng-template>
      <div class="d-flex align-items-center justify-content-between pb-12">
        <div translate="Language"></div>
        <ng-template *ngTemplateOutlet="langTemp"></ng-template>
      </div>
    </div>
    <hr class="m-0">
    <div class="px-20 py-16" translate="Log out" (click)="logout()"></div>
  </ng-container>
  <div *ngIf="!isAuth" class="d-flex align-items-center justify-content-between px-20 py-12">
    <div translate="Language"></div>
    <ng-template *ngTemplateOutlet="langTemp"></ng-template>
  </div>
</div>
<div class="backdrop d-xl-none" [@fadeInOut]="state" [class.d-none]="state === 'out'" (click)="close()"></div>

<ng-template #menuTemp>
  <ul class="d-flex navbar-nav h-100">
    <li class="nav-item position-relative d-xl-flex align-items-center p-0" *ngFor="let item of menu"
      routerLinkActive="active">
      <a class="nav-link" [routerLink]="item.to" [translate]="item.title" (click)="close()"></a>
      <div class="underline"></div>
    </li>
  </ul>
</ng-template>
<ng-template #accountTemp>
  <div class="btn-group" dropdown [isAnimated]="true">
    <div dropdownToggle class="cursor_pointer d-flex align-items-center">
      <i *ngIf="!user.avatar" class="far fa-user-circle text-secondary"></i>
      <img *ngIf="user.avatar" [src]="user.avatar" alt="" class="user_photo">
      <h6 class="mx-8">{{ user.name }}</h6>
      <i class="far fa-angle-down text-secondary"></i>
    </div>
    <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
      <div class="mt-16 mx-12">
        <ng-template [ngTemplateOutlet]="userTemp"></ng-template>
      </div>
      <hr class="my-12">
      <ng-template [ngTemplateOutlet]="profileTemp"></ng-template>
      <hr class="my-12">
      <ul>
        <li (click)="logout()">
          <a class="dropdown-item d-flex align-items-center px-24 cursor_pointer">
            <div class="ms-12" translate="Log out"></div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
<ng-template #userTemp>
  <div class="d-flex align-items-center">
    <i *ngIf="!user.avatar" class="far fa-user-circle text-secondary"></i>
    <img *ngIf="user.avatar" [src]="user.avatar" alt="" class="user_photo">
    <h6 class="ms-8">{{ user.name }}</h6>
  </div>
  <div class="text-secondary mx-24">{{ user.email }}</div>
</ng-template>
<ng-template #langTemp>
  <div class="btn-group" dropdown [isAnimated]="true">
    <div dropdownToggle class="cursor_pointer d-flex align-items-center">
      <img *ngIf="lang" [src]="'assets/imgs/' + lang.code + '.svg'" alt="" class="me-8">
      <i class="far fa-angle-down text-secondary"></i>
    </div>
    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
      <li *ngFor="let item of langs" (click)="setLang(item)">
        <a class="dropdown-item d-flex align-items-center cursor_pointer">
          <img [src]="'assets/imgs/' + item.code + '.svg'" alt="" [style.height]="'2rem'">
          <small class="fw-bold ms-16 me-30">{{ item.code | translate }}</small>
          <i *ngIf="item.active" class="fa fa-check text-primary ms-auto"></i>
        </a>
      </li>
    </ul>
  </div>
</ng-template>
<ng-template #profileTemp>
  <ul class="navbar-nav flex-xl-column">
    <li class="nav-item p-0 m-0" *ngFor="let item of userMenu" routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link dropdown-item d-flex align-items-center px-xl-24 py-xl-6 cursor_pointer" [routerLink]="item.to"
        (click)="close()">
        <div class="ms-xl-12" [translate]="item.title"></div>
      </a>
    </li>
  </ul>
</ng-template>