import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { HttpClientModule, HttpClient } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { NgProgressHttpModule } from 'ngx-progressbar/http'
import { NgProgressModule } from 'ngx-progressbar'
import { NgProgressRouterModule } from 'ngx-progressbar/router'
import { ToastrModule } from 'ngx-toastr'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { HeaderComponent } from './shared/components/header/header.component'

class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle = (params: MissingTranslationHandlerParams): string => {
    return params.key
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    BsDropdownModule.forRoot(),
    HttpClientModule,
    NgProgressHttpModule,
    NgProgressModule.withConfig({
      color: 'var(--bs-primary)',
      thick: true
    }),
    NgProgressRouterModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5e3,
      enableHtml: true,
      progressBar: true,
      preventDuplicates: true,
      positionClass: 'toast-bottom-right'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient): TranslateHttpLoader => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json')
        },
        deps: [HttpClient]
      },
      defaultLanguage: 'ko',
      useDefaultLang: false,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      }
    })
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
