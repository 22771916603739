import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'

import { EventBusService } from 'app/services/event-bus.service'
import { getStorageItem, setStorageItem } from 'app/utils/utils'
import { IUser } from 'app/types/interface'
import { slideInOut, fadeInOut } from 'app/utils/animations'
import { WebsocketService } from 'app/services/websocket.service'

interface ILanguage {
  code: string
  active: boolean
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInOut, fadeInOut]
})

export class HeaderComponent implements OnInit, OnDestroy {

  isAuth = !!localStorage.getItem('token')
  lang: ILanguage
  langs: ILanguage[]
  menu: { title: string, to: string[] }[]
  state = 'out'
  user: IUser = getStorageItem('userInfo')
  userMenu: { title: string, to: string[] }[]
  private sub = new Subscription()

  constructor(
    private cdr: ChangeDetectorRef,
    private eventBus: EventBusService,
    private router: Router,
    private translateService: TranslateService,
    private ws: WebsocketService,
  ) { }

  ngOnInit(): void {
    this.initLangs()
    this.initUserMenu()
    this.initMenu()
    this.listenEventBus()
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  setLang(lang: ILanguage): void {
    this.lang = lang
    this.translateService.use(lang.code)
    this.langs = this.langs.map(t => ({
      ...t,
      active: t.code === lang.code
    }))
    localStorage.setItem('lang', lang.code)
  }

  login(): void {
    const { pathname, search } = window.location
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: pathname + search } })
  }

  logout(): void {
    this.ws.logout()
    this.isAuth = false
    this.initMenu()
  }

  close(): void {
    this.state = 'out'
  }

  private listenEventBus(): void {
    this.sub.add(
      this.eventBus.on('isAuth').subscribe((token: string) => {
        this.isAuth = !!token
        this.user = getStorageItem('userInfo')
        this.initMenu()
      })
    ).add(
      this.eventBus.on('userInfo').subscribe((res: IUser) => {
        this.user = res
        const user = getStorageItem('userInfo')
        setStorageItem('userInfo', { ...user, ...res })
        this.cdr.detectChanges()
      })
    )
  }

  private initUserMenu(): void {
    this.userMenu = [{
      title: 'Profile',
      to: ['/account']
    }, {
      title: 'IPO History',
      to: ['/account', 'ipo-history']
    }, {
      title: 'Trading History',
      to: ['/account', 'trading-history']
    }, {
      title: 'User’s NFT History',
      to: ['/account', 'nft-history']
    }, {
      title: 'Order History',
      to: ['/account', 'order-history']
    }, {
      title: 'Swap History',
      to: ['/account', 'swap-history']
    }, {
      title: 'Balance History',
      to: ['/account', 'balance-history']
      // }, {
      //   title: 'Login History',
      //   to: ['/account', 'login-history']
    }, {
      title: 'User’s NFT Withdrawal History',
      to: ['/account', 'nft-withdrawal-history']
    }]
  }

  private initMenu(): void {
    const shared = [{
      title: 'IPO',
      to: ['/ipo']
    }, {
      title: 'Exchange',
      to: ['/exchange']
    }, {
      title: 'Marketplace',
      to: ['/market']
    }, {
      title: 'TAF',
      to: ['taf']
    }]
    const oShared = [{
      title: 'Notice',
      to: ['/notice']
    }, {
      title: 'How To Use',
      to: ['/htu']
    }]
    this.menu = this.isAuth ? [...shared, {
      title: 'Wallet',
      to: ['/wallet']
    }, ...oShared] : [...shared, ...oShared]
    this.cdr.detectChanges()
  }

  private initLangs(): void {
    this.langs = [{
      code: 'en',
      active: true
    }, {
      code: 'ko',
      active: false
    }].map(t => ({
      ...t,
      active: t.code === (localStorage.getItem('lang') || this.translateService.getDefaultLang())
    }))
    this.lang = this.langs.find(t => t.active)
  }

}
