import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.production) {
  enableProdMode()
}

const script = document.createElement('script')
script.async = true
script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gtag}`
const sScript = document.createElement('script')
sScript.textContent = `
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', '${environment.gtag}');
`
document.head.appendChild(script)
document.head.appendChild(sScript)

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err))
