import { Injectable } from '@angular/core'
import { interval, Subject, Subscription } from 'rxjs'
import { retryWhen, tap, delay } from 'rxjs/operators'
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { webSocket, WebSocketSubject } from 'rxjs/webSocket'

import { environment } from 'environments/environment'
import { ISocketMessage } from 'app/types/interface'

@Injectable({ providedIn: 'root' })
export class WebsocketService {

  messages$ = new Subject<any>()
  private store: ISocketMessage[] = []
  private sub = new Subscription()
  private ws: WebSocketSubject<ISocketMessage>

  constructor(
    private toastr: ToastrService,
    private router: Router,
  ) { }

  connect(): void {
    if (this.ws && !this.ws.closed) {
      return
    }
    this.ws = this.getNewWebSocket()
    this.ws.pipe(
      tap((t: any) => {
        if (t.status === 'failure') {
          switch (t.error_code) {
            case 'TOKEN_IS_INVALID':
              this.logout()
              break
            default:
              break
          }
          this.toastr.error(t.error_code)
        }
      }),
      retryWhen(errors => errors.pipe(
        tap(_ => {
          console.log('[websocket] Reconnect')
        }),
        delay(5e3)
      )),
    ).subscribe(_ => {
      this.messages$.next(_)
    })
    this.ping()
  }

  sendMessage(msg: ISocketMessage): void {
    this.setStore(msg)
    this.next(msg)
  }

  login(): void {
    this.sendMessage({
      type: 'login',
      token: localStorage.getItem('token')
    })
  }

  logout(): void {
    localStorage.removeItem('token')
    this.router.navigate(['/auth/login'])
    this.close()
  }

  private ping(): void {
    this.sub.add(
      interval(1e4).subscribe(() => {
        this.sendMessage({ type: 'ping' })
      })
    )
  }

  private close(): void {
    this.store = []
    this.ws.unsubscribe()
    this.sub.unsubscribe()
  }

  private next(msg: ISocketMessage): void {
    this.ws.next(msg)
  }

  private setStore(msg: ISocketMessage): void {
    const { data, type } = msg
    if (!['subscribe', 'unsubscribe'].includes(type)) {
      return
    }
    const index = this.store.map(t => JSON.stringify(t.data)).indexOf(JSON.stringify(data))
    if (type === 'subscribe') {
      if (index === -1) {
        this.store.push(msg)
      }
    } else {
      this.store.splice(index, 1)
    }
  }

  private getNewWebSocket(): WebSocketSubject<ISocketMessage> {
    return webSocket({
      url: environment.wsUri,
      openObserver: {
        next: () => {
          console.log('[websocket] Connected: ', new Date())
          this.login()
          this.store.forEach(t => this.next(t))
        }
      },
      closeObserver: {
        next: () => {
          console.log('[websocket] Closed')
        }
      },
    })
  }

}
