import { ChangeDetectionStrategy, Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent {

  constructor(
    titleService: Title,
    translateService: TranslateService,
  ) {
    translateService.use(localStorage.getItem('lang') || translateService.getDefaultLang())
    translateService.onLangChange.subscribe(() => {
      titleService.setTitle(translateService.instant('TigerList'))
    })
  }

}
